import './Footer.css';
import React from 'react';
import HomeSmartLogo from "../../Images/homesmart-logo.svg";
// import HomeSmartLogo2 from "../../Images/HomeSmart.png";
import InstagramLogo from "../../Images/instagram.svg";
import MailLogo from "../../Images/mail.svg";

const Footer = () => {
    return (
      <footer className='footer'>
        <div className='footer-content'>
          {/* Logo and Office Info */}
          <div className='footer-logo-text'>
            <div className='footer-logo '>
              <a href='https://homesmart.com' target='_blank' rel='noreferrer'><img src={HomeSmartLogo} alt='HomeSmart Logo' /></a>
            </div>
            <div className='office-info'>
                <a href='https://maps.apple.com/?address=9680%20Haven%20Ave,%20Rancho%20Cucamonga,%20CA%20%2091730,%20United%20States&ll=34.078604,-117.577056&q=9680%20Haven%20Ave&t=m'>
                    <p>HomeSmart</p>
                    <p>9680 Haven Ave, Suite 120</p>
                    <p>Rancho Cucamonga, CA 91730</p>
                </a>
            </div>
          </div>
          <hr className='solid'/>
          {/* Contact Info */}
          <div className='contact-info'>
            <p className='name'>Xavier Guerrero</p>
            <div className='social'><a href='https://www.instagram.com/real.agent.x/' target='_blank' rel='noreferrer'><img src={InstagramLogo} alt='Instagram Logo'/></a><a href='mailto:xavier.guerr@outlook.com'><img src={MailLogo} alt='Mail Xavier'/></a></div>
            <p className='call'><a href='+1909-663-8007'>P: 909.663.8007</a> / <a href='+1909-302-0239'>O: 909.302.0239</a></p>
          </div>
        </div>
      </footer>
    );
};
  
export default Footer;